import { ApiHttpClient } from "../../common/http/api-http-client";
import { ResourceBaseService } from "../../common/resource-base/resource-base.service";
import { HttpRequest } from "@/core/common/http/http-request";
import { FindManyResult } from "@/core/common/resource-base/find-many-result.dto";
import { IRequest } from "@/interfaces/IRequest";
import { plainToInstance } from "class-transformer";
import { OnCallDto } from "@/core/features/on-calls/on-call.dto";
import { CreateOnCallDto } from "@/core/features/on-calls/create-on-call.dto";
import { UpdateOnCallDto } from "@/core/features/on-calls/update-on-call.dto";
import { OnCallRecordDto } from "@/core/features/on-calls/on-call-record.dto";
import { SuperviseOnCallDto } from "@/core/features/on-calls/supervise-on-call.dto";
import { ExpenseDto } from "@/core/features/expense/expense.dto";

export class OnCallsResource extends ResourceBaseService<OnCallDto, CreateOnCallDto, UpdateOnCallDto> {
  protected EntityDto = OnCallDto;
  protected CreateEntityDto = CreateOnCallDto;
  protected UpdateEntityDto = UpdateOnCallDto;
  protected RecordEntityDto = OnCallRecordDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/on-calls");
  }

  async getMyOnCalls(request: IRequest = {}): Promise<FindManyResult<OnCallDto>> {
    return this.getMine(request);
  }

  setApproved(id: string, approveOnCall: SuperviseOnCallDto): Promise<OnCallDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-approved",
        method: "PATCH",
        auth: true,
        body: plainToInstance(SuperviseOnCallDto, approveOnCall),
      })
    );
  }

  setRejected(id: string, rejectOnCall: SuperviseOnCallDto): Promise<OnCallDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/set-rejected",
        method: "PATCH",
        auth: true,
        body: plainToInstance(SuperviseOnCallDto, rejectOnCall),
      })
    );
  }

  async getMyRejectedCount(projectId: string): Promise<number> {
    const request: IRequest = {
      filters: {
        projectId: projectId,
      },
    };
    return this.apiHttpClient
      .request<null, number>(
        HttpRequest.create({
          url: this.resourceUrl + "/count/rejected",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => Number(result));
  }

  async getSupervisionCount(request: IRequest = {}): Promise<number> {
    return this.apiHttpClient
      .request<null, number>(
        HttpRequest.create({
          url: this.resourceUrl + "/count/supervision",
          method: "GET",
          auth: true,
          query: this.getQueryBuilder(request).query(),
        })
      )
      .then((result) => Number(result));
  }

  async deleteFromCertification(id: string): Promise<ExpenseDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/delete-certification",
        method: "PATCH",
        auth: true,
        body: {},
      })
    );
  }
}
