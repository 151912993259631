export enum PermissionEnum {
  PROJECTS_DOSSIER = "PROJECTS_DOSSIER",
  PROJECTS_PROJECT = "PROJECTS_PROJECT",
  PROJECTS_WORKSHEET = "PROJECTS_WORKSHEET",
  PROJECT_WORKSHEET_VALIDATION = "PROJECT_WORKSHEET_VALIDATION",
  PROJECTS_FILES = "PROJECTS_FILES",
  PROJECTS_EXPENSES = "PROJECTS_EXPENSES",
  PROJECTS_ECONOMIC_ANALYSIS = "PROJECTS_ECONOMIC_ANALYSIS",
  PROJECT_CALENDAR = "PROJECT_CALENDAR",

  SUPERVISION_TIMES_OFF = "SUPERVISION_TIMES_OFF",
  SUPERVISION_WORKSHEET = "SUPERVISION_WORKSHEET",
  SUPERVISION_ON_CALL = "SUPERVISION_ON_CALL",
  /* @Deprecated() */ SUPERVISION_EXPENSES = "SUPERVISION_EXPENSES",

  CERTIFICATIONS_PROJECTS = "CERTIFICATIONS_PROJECTS",

  SCHEDULER_CALENDAR = "SCHEDULER_CALENDAR",
  SCHEDULER_ORP = "SCHEDULER_ORP",
  SCHEDULER_ORP_CONFIGURATION = "SCHEDULER_ORP_CONFIGURATION",

  FLEET_VEHICLES = "FLEET_VEHICLES",
  FLEET_VEHICLES_ISSUES = "FLEET_VEHICLES_ISSUES",
  FLEET_VEHICLES_USAGE = "FLEET_VEHICLES_USAGE",
  FLEET_VEHICLE_TYPE_CHECKLIST = "FLEET_VEHICLE_TYPE_CHECKLIST",

  HR_TIMES_OFF = "HR_TIMES_OFF",
  HR_ON_CALL = "HR_ON_CALL",
  HR_EMPLOYEES = "HR_EMPLOYEES",
  HR_TIMES_OFF_VALIDATION = "HR_TIMES_OFF_VALIDATION",
  HR_POSTS = "HR_POSTS",
  HR_CONFIGURATION = "HR_CONFIGURATION",

  WAREHOUSE_WAREHOUSES = "WAREHOUSE_WAREHOUSES",
  WAREHOUSE_CONSUMABLES = "WAREHOUSE_CONSUMABLES",
  WAREHOUSE_ITEMS = "WAREHOUSE_ITEMS",
  WAREHOUSE_STOCK_RECORDS = "WAREHOUSE_STOCK_RECORDS",
  WAREHOUSE_CONFIG_SUPPLIERS = "WAREHOUSE_CONFIG_SUPPLIERS",
  WAREHOUSE_CONFIGURATION = "WAREHOUSE_CONFIGURATION",

  REPORTS_GROUP_BY = "REPORTS_GROUP_BY",
  REPORTS_USER_ACTIVITY = "REPORTS_USER_ACTIVITY",
  REPORTS_TIMES_OFF = "REPORTS_TIME_OFF",
  REPORTS_PERMISSIONS = "REPORTS_PERMISSIONS",
  REPORTS_PROFITABILITY = "REPORTS_PROFITABILITY",

  CONFIGURATION_ORGANIZATION = "CONFIGURATION_ORGANIZATION",
  CONFIGURATION_ACCOUNTING = "CONFIGURATION_ACCOUNTING",
  CONFIGURATION_COMMERCIAL = "CONFIGURATION_COMMERCIAL",
  CONFIGURATION_STRUCTURE = "CONFIGURATION_STRUCTURE",
  CONFIGURATION_FILES = "CONFIGURATION_FILES",
  CONFIGURATION_WORKSHEETS = "CONFIGURATION_WORKSHEETS",
  CONFIGURATION_ACCESS = "CONFIGURATION_ACCESS",
  CONFIGURATION_ZONES = "CONFIGURATION_ZONES",

  NOTIFY_ERROR = "NOTIFY_ERROR",
}

export const hasPermission = (value: PermissionEnum, permissions: PermissionEnum[]): boolean => {
  const permissionIndex = permissions.indexOf(value);
  return permissionIndex >= 0;
};

export const getPermissionsLabel = (permission: PermissionEnum): string => {
  switch (permission) {
    case PermissionEnum.PROJECTS_DOSSIER:
      return "Expedientes";
    case PermissionEnum.PROJECTS_PROJECT:
      return "Proyectos";
    case PermissionEnum.PROJECTS_WORKSHEET:
      return "Partes";
    case PermissionEnum.PROJECT_WORKSHEET_VALIDATION:
      return "Validación de partes";
    case PermissionEnum.PROJECTS_EXPENSES:
      return "Gastos";
    case PermissionEnum.PROJECTS_FILES:
      return "Archivos";
    case PermissionEnum.PROJECTS_ECONOMIC_ANALYSIS:
      return "Análisis económico";
    case PermissionEnum.SUPERVISION_WORKSHEET:
      return "Supervisión de partes";
    case PermissionEnum.SUPERVISION_EXPENSES:
      return "Supervisión de gastos";
    case PermissionEnum.SUPERVISION_TIMES_OFF:
      return "Supervisión de ausencias";
    case PermissionEnum.SUPERVISION_ON_CALL:
      return "Supervisión de guardias";
    case PermissionEnum.CERTIFICATIONS_PROJECTS:
      return "Certificaciones";
    case PermissionEnum.SCHEDULER_CALENDAR:
      return "Planificador";
    case PermissionEnum.SCHEDULER_ORP:
      return "PRL - Prevención de Riesgos Laborales";
    case PermissionEnum.FLEET_VEHICLES:
      return "Vehículos";
    case PermissionEnum.FLEET_VEHICLES_ISSUES:
      return "Incidencias en vehículos";
    case PermissionEnum.FLEET_VEHICLES_USAGE:
      return "Uso de vehículos";
    case PermissionEnum.FLEET_VEHICLE_TYPE_CHECKLIST:
      return "Plantillas de checklist";
    case PermissionEnum.PROJECT_CALENDAR:
      return "Informe de calendario";
    case PermissionEnum.HR_POSTS:
      return "Comunicados";
    case PermissionEnum.HR_TIMES_OFF:
      return "Ausencias";
    case PermissionEnum.HR_ON_CALL:
      return "Guardias";
    case PermissionEnum.HR_EMPLOYEES:
      return "Empleados";
    case PermissionEnum.HR_TIMES_OFF_VALIDATION:
      return "Validación de ausencias";
    case PermissionEnum.WAREHOUSE_WAREHOUSES:
      return "Almacenes";
    case PermissionEnum.WAREHOUSE_CONSUMABLES:
      return "Consumibles";
    case PermissionEnum.WAREHOUSE_ITEMS:
      return "Artículos";
    case PermissionEnum.WAREHOUSE_STOCK_RECORDS:
      return "Registros de stock";
    case PermissionEnum.WAREHOUSE_CONFIG_SUPPLIERS:
      return "Proveedores";
    case PermissionEnum.REPORTS_GROUP_BY:
      return "Informe de horas/hombre";
    case PermissionEnum.REPORTS_USER_ACTIVITY:
      return "Informe de actividad de usuario";
    case PermissionEnum.REPORTS_TIMES_OFF:
      return "Informe de ausencias";
    case PermissionEnum.REPORTS_PERMISSIONS:
      return "Informe de permisos";
    case PermissionEnum.REPORTS_PROFITABILITY:
      return "Informe de rentabilidad";
    case PermissionEnum.CONFIGURATION_ORGANIZATION:
      return "Organización";
    case PermissionEnum.CONFIGURATION_ACCOUNTING:
      return "Contabilidad";
    case PermissionEnum.CONFIGURATION_COMMERCIAL:
      return "Comercial";
    case PermissionEnum.SCHEDULER_ORP_CONFIGURATION:
      return "Configuración PRL";
    case PermissionEnum.WAREHOUSE_CONFIGURATION:
      return "Configuración almacén";
    case PermissionEnum.HR_CONFIGURATION:
      return "Configuración recursos humanos";
    case PermissionEnum.CONFIGURATION_STRUCTURE:
      return "Estructura";
    case PermissionEnum.CONFIGURATION_FILES:
      return "Archivos";
    case PermissionEnum.CONFIGURATION_WORKSHEETS:
      return "Partes";
    case PermissionEnum.CONFIGURATION_ACCESS:
      return "Acceso";
    case PermissionEnum.CONFIGURATION_ZONES:
      return "Zonas";
    case PermissionEnum.NOTIFY_ERROR:
      return "Reportar error";
    default:
      return null;
  }
};
