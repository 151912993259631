import { RouteRecordRaw } from "vue-router";
import { PermissionEnum } from "@altertec_gparn/lib";

export default {
  path: "fleet",
  name: "Fleet",
  meta: { sectionAccess: "FLEET", redirects: true },
  component: () => import("@/layouts/EmptyLayout.vue"),
  children: [
    {
      path: "vehicles",
      name: "Vehicles Layout",
      redirect: { name: "Vehicle List" },
      component: () => import("@/layouts/EmptyLayout.vue"),
      meta: { permissions: [PermissionEnum.FLEET_VEHICLES] },
      children: [
        {
          path: "list",
          name: "Vehicle List",
          component: () => import("@/views/auth/fleet/Vehicles.vue"),
        },
        {
          path: "new",
          name: "New Vehicle",
          component: () => import("@/views/auth/fleet/NewVehicle.vue"),
        },
        {
          path: ":id",
          name: "Vehicle Detail",
          redirect: { name: "Vehicle Data" },
          component: () => import("@/views/auth/fleet/VehicleDetail.vue"),
          props: true,
          children: [
            {
              path: "data",
              name: "Vehicle Data",
              component: () => import("@/modules/fleet/components/tabs/VehicleDataTab.vue"),
              props: true,
            },
            {
              path: "mileages",
              name: "Vehicle Mileages",
              component: () => import("@/modules/fleet/components/tabs/MileagesTab.vue"),
              props: true,
            },
            {
              path: "equipment",
              name: "Vehicle Equipment",
              component: () => import("@/modules/fleet/components/tabs/VehicleEquipmentTab.vue"),
              props: true,
            },
            {
              path: "documentation",
              name: "Vehicle's Documentation",
              components: {
                default: () => import("@/modules/fleet/components/tabs/VehicleHasDocumentationTab.vue"),
                sidebar: () => import("@/modules/fleet/components/sidebars/VehicleSidebar.vue"),
              },
              props: true,
            },
            {
              path: "maintenance",
              name: "Vehicle Maintenance",
              components: {
                default: () => import("@/modules/fleet/components/tabs/VehicleMaintenanceTab.vue"),
                sidebar: () => import("@/modules/fleet/components/sidebars/VehicleSidebar.vue"),
              },
              props: true,
            },
          ],
        },
        {
          path: ":id/edit",
          name: "Vehicle Edit",
          component: () => import("@/views/auth/fleet/EditVehicle.vue"),
          props: true,
        },
      ],
    },
    {
      path: "vehicles-usage",
      name: "Vehicles Usage",
      meta: { permissions: [PermissionEnum.FLEET_VEHICLES_USAGE] },
      component: () => import("@/views/auth/fleet/VehiclesUsage.vue"),
      props: true,
    },
    {
      path: "mileage",
      name: "Mileage List",
      redirect: { name: "Mileage List Sidebar" },
      component: () => import("@/views/auth/fleet/MileageList.vue"),
      children: [
        {
          path: "",
          name: "Mileage List Sidebar",
          components: {
            sidebar: () => import("@/modules/fleet/components/sidebars/MileageSidebar.vue"),
          },
        },
      ],
    },
    {
      path: "checklist",
      name: "Checklist Layout",
      redirect: { name: "Checklist List" },
      meta: { permissions: [PermissionEnum.FLEET_VEHICLE_TYPE_CHECKLIST] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          name: "Checklist List",
          path: "",
          component: () => import("@/views/auth/fleet/vehicle-checklist/VehicleChecklistList.vue"),
        },
        {
          name: "Checklist Detail",
          path: ":checklistId",
          component: () => import("@/views/auth/fleet/vehicle-checklist/VehicleChecklistDetail.vue"),
          props: true,
          children: [
            {
              path: "create-question",
              name: "Create Question Sidebar",
              components: {
                sidebar: () => import("@/modules/fleet/components/sidebars/CreateQuestionSidebar.vue"),
              },
              props: true,
            },
            {
              path: "edit-question/:questionId",
              name: "Edit Question Sidebar",
              components: {
                sidebar: () => import("@/modules/fleet/components/sidebars/EditQuestionSidebar.vue"),
              },
              props: true,
            },
            {
              path: "create-subquestion",
              name: "Create Subquestion Sidebar",
              components: {
                sidebar: () => import("@/modules/fleet/components/sidebars/CreateSubquestionSidebar.vue"),
              },
              props: true,
            },
            {
              path: "edit-subquestion/:questionId",
              name: "Edit Subquestion Sidebar",
              components: {
                sidebar: () => import("@/modules/fleet/components/sidebars/EditSubquestionSidebar.vue"),
              },
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: "issues",
      name: "Vehicle Issues Layout",
      redirect: { name: "Vehicle Issues List" },
      meta: { permissions: [PermissionEnum.FLEET_VEHICLES_ISSUES] },
      component: () => import("@/layouts/EmptyLayout.vue"),
      children: [
        {
          name: "Vehicle Issues List",
          path: "",
          component: () => import("@/views/auth/fleet/vehicle-issues/VehicleIssuesList.vue"),
          children: [
            {
              path: "create-vehicle-issue",
              name: "Create Vehicle Issue Sidebar",
              components: {
                sidebar: () => import("@/modules/fleet/components/sidebars/CreateVehicleIssueSidebar.vue"),
              },
            },
          ],
        },
        {
          name: "Vehicle Issue Detail",
          path: "detail/:id",
          component: () => import("@/views/auth/fleet/vehicle-issues/VehicleIssueDetail.vue"),
          props: true,
          children: [
            {
              name: "Edit Vehicle Issue Sidebar",
              path: "edit-issue",
              components: {
                sidebar: () => import("@/modules/fleet/components/sidebars/EditVehicleIssueSidebar.vue"),
              },
              props: true,
            },
          ],
        },
      ],
    },
  ],
} as RouteRecordRaw;
